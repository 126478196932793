
import { Component, Vue } from 'vue-property-decorator';
import { changePassword, IUpdatePassword } from '@/api/user';



@Component({})
export default class ChangePassword extends Vue {
  public name: string = 'ChangePassword';
  public visible: boolean = false;
  public form: any;
  public confirm: boolean = false;
  public beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: 'change-password' });
  }
  // public created(): void {}
  public showModal() {
    this.visible = true;
    this.form.resetFields()
  }
  public handleOk() {
    this.form.validateFields(async (err: object, values: IUpdatePassword) => {
      if (!err) {
        try {
          const data = await changePassword(values);
          if (data.status) {
            this.visible = false;
            this.$message.success('Success');
          } else {
            this.$message.error(data.message);
          }
        } catch (e) {
          this.$message.error((e as Error).message);
        }
      }
    });
  }
  public validateToNextPassword(rule: object, value: string, callback: (val?: string) => void) {
    const form = this.form;
    if (value && this.confirm) {
      form.validateFields(['newPassword'], { force: true });
    }
    callback();
  }
  public compareToFirstPassword(rule: object, value: string, callback: (val?: string) => void) {
    const form = this.form;
    if (value && value === form.getFieldValue('oldPassword')) {
      callback('The two passwords are the same!');
    } else {
      callback();
    }
  }
  public handleConfirmBlur(e: any) {
    const value = e.target.value;
    this.confirm = this.confirm || !!value;
  }
}
