var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-modal',{attrs:{"title":"Update Password","maskClosable":false},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'oldPassword',
            {
              rules: [
                { required: true, message: 'Please input old password!' },
                { validator: _vm.validateToNextPassword },
              ]
            },
          ]),expression:"[\n            'oldPassword',\n            {\n              rules: [\n                { required: true, message: 'Please input old password!' },\n                { validator: validateToNextPassword },\n              ]\n            },\n          ]"}],attrs:{"type":"password","placeholder":"Old Password"}})],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'newPassword',
            { rules: [
              { required: true, message: 'Please input your new password!' },
              { type: 'string', pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,  message: 'At least 8 characters, and must contain letters and numbers,' },
              { validator: _vm.compareToFirstPassword },
            ] },
          ]),expression:"[\n            'newPassword',\n            { rules: [\n              { required: true, message: 'Please input your new password!' },\n              { type: 'string', pattern: /^(?=.*[a-zA-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$/,  message: 'At least 8 characters, and must contain letters and numbers,' },\n              { validator: compareToFirstPassword },\n            ] },\n          ]"}],attrs:{"type":"password","placeholder":"New Password"},on:{"blur":_vm.handleConfirmBlur}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }