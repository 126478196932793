import apiRequest from "./libs/apiRequest";

export interface IUpdatePassword {
  oldPassword: string;
  newPassword: string;
}

// 获取全部用户
export const getAllUser = (): Promise<IResponse.User.IGetUserList> => {
  return apiRequest.get({
    url: '/getAllUser',
  });
}
// 获取全部用户
export const updateUserFrozen = (userId: string): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: '/frozenUser/' + userId,
  });
}
// 修改用户密码
export const changePassword = (data: IUpdatePassword): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: '/changePassword',
    data
  });
}
