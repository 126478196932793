
import { Component, Vue } from 'vue-property-decorator';
import storage from '@/utils/storage.ts';
import ChangePassword from './changePassword/ChangePassword.vue';

@Component({
  components: {
    ChangePassword,
  }
})
export default class Admin extends Vue {
  public username: string = ""
  public isAdmin: boolean = false
  public $refs!: {
    ChangePasswordRef: ChangePassword;
  };
  public created(): void {
    this.initUsername();
  }
  public initUsername(): void {
    const userInfo = storage.localGet('version_user_info');
    if (userInfo) {
      this.username = userInfo.username;
      this.isAdmin = userInfo.isAdmin || false;
    }
  }
  public logout(): void {
    storage.localRemove('version_user_info');
    this.$router.push('Login');
  }
  public handleManageUser() {
    if (this.$route.name !== 'userManagement') {
      this.$router.push({
        name: 'userManagement'
      })
    }
  }
  public handleChangePassword() {
    this.$refs.ChangePasswordRef.showModal()
  }
}
